import React from 'react'
import { get, camelCase, isEmpty } from 'lodash'
import { Box, Grid, FormLabel, Flex, Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import { languagesConfig, getFieldComponents } from './helper'
import { AddressProvider } from '@/lib/address'
import { mq } from '@/lib/responsive'
import { HelperText } from '@/features/formGenerator/Field/FormControl'

function Address(props) {
  const {
    label,
    name,
    value,
    error,
    isRequired,
    lang = ['th', 'en'],
    helper_text: helperText,
    ...restProps
  } = props
  const { setFieldValue } = useFormikContext()

  const languages = languagesConfig.filter(({ language }) =>
    lang.includes(language),
  )

  return (
    <AddressProvider>
      <Box>
        <FormLabel fontSize="18px">
          <Flex>
            <Box color="#2b2b2b" mr="8px">
              {label}
            </Box>
            {isRequired && (
              <Box color="#db6464" mr="8px" aria-hidden="true">
                *
              </Box>
            )}
            {!isEmpty(helperText) && <HelperText lines={helperText} />}
          </Flex>
        </FormLabel>

        <Grid
          css={{
            gridTemplateColumns: '1fr',

            [mq('md+')]: {
              gridTemplateColumns: 'unset',
              gridAutoFlow: 'column',
              columnGap: 16,
            },
          }}
        >
          {languages.map(({ language, label, inputs }) => {
            const prefix = `${name}.${language}`

            return (
              <Box key={prefix} mb="16px">
                <Text fontSize="14px" color="gray.600" >
                  {label}
                </Text>
                {inputs.map(({ key, type, ...rest }) => {
                  const name = `${prefix}.${key}`
                  const Renderer = getFieldComponents(type)

                  return (
                    <Renderer
                      key={name}
                      {...restProps}
                      {...rest}
                      lang={language}
                      field={camelCase(key)}
                      name={name}
                      value={get(value, `${language}.${key}`)}
                      error={get(error, `${language}.${key}`)}
                      onClick={(e) => setFieldValue(name, e.target.value)}
                    />
                  )
                })}
              </Box>
            )
          })}
        </Grid>
      </Box>
    </AddressProvider>
  )
}

export default Address
