import React, {
  useState,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
} from 'react'
import { FormikProvider, useFormik, Form, FormikValues } from 'formik'
import { noop } from 'lodash'

import FormElement from './FormElement'
import { withFormGeneratorProvider, useFormGeneratorContext } from './context'

type Props = {
  renderSubmitButton?: () => void
  onSubmit?: (data: any) => void
}

function FormContainer(props: Props, ref: ForwardedRef<FormikValues>) {
  const { renderSubmitButton = noop, onSubmit = noop } = props

  const { initialValues } = useFormGeneratorContext()
  const [validationSchema, setValidationSchema] = useState()

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true, // require for reinitialze values that was removed from initialValues state
    validateOnBlur: false,
    validateOnChange: false,
  })

  useImperativeHandle(ref, () => formik)

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <FormElement
          renderSubmitButton={renderSubmitButton}
          setValidationSchema={setValidationSchema}
        />
      </Form>
    </FormikProvider>
  )
}

export default withFormGeneratorProvider(forwardRef(FormContainer))
