import { Fragment } from 'react'
import { isEmpty } from 'lodash'
import {
  Box,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Icon,
} from '@chakra-ui/react'
import { FiHelpCircle } from 'react-icons/fi'

type helperText = {
  text: string
  bold?: boolean
  href: string
}[]

export type FormControlProps = {
  className?: string
  name: string
  label: string
  description?: string
  isRequired?: boolean
  helperText?: helperText[]
  error?: string
  touched?: boolean
  children: React.ReactNode
}

function FormControl(props: FormControlProps): React.ReactElement {
  const {
    className,
    name,
    label,
    description,
    isRequired,
    helperText = [],
    error,
    touched,
  } = props

  const hasLabelSection =
    !isEmpty(label) || !isEmpty(description) || !isEmpty(helperText)

  return (
    <div className={className}>
      <FormLabel
        htmlFor={name}
        fontSize="18px"
        my={hasLabelSection ? '8px' : '4px'}
        display={'block'}
      >
        <Flex flexDirection="column">
          <Box>
            {label && (
              <Box color="#2b2b2b" mr="8px" d="inline">
                {label}
              </Box>
            )}
            {isRequired && (
              <Box color="#db6464" mr="8px" aria-hidden="true" d="inline">
                *
              </Box>
            )}
            {!isEmpty(helperText) && <HelperText lines={helperText} />}
          </Box>
          {description && <Flex fontSize="14px">{description}</Flex>}
        </Flex>
      </FormLabel>
      {props.children}
      {error && (
        <ErrorMsg isError={Boolean(error && touched)} errorMsg={error} />
      )}
    </div>
  )
}

type ErrorMsgProps = {
  isError: boolean
  errorMsg: string
}

const ErrorMsg = ({ isError, errorMsg }: ErrorMsgProps) => {
  if (isError) {
    return (
      <div
        css={{
          marginTop: '5px',
          color: 'red',
          fontSize: '12px',
        }}
      >
        {errorMsg}
      </div>
    )
  }

  return null
}

type HelperTextProps = {
  lines?: helperText[]
}

export const HelperText = ({
  lines = [],
}: HelperTextProps): React.ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Fragment>
      <Flex
        sx={{
          d: 'inline-flex',
          alignItems: 'center',
          verticalAlign: 'middle',
          cursor: 'pointer',
          transform: 'translateY(-2px)',
        }}
        data-testid="helperText"
        onClick={onOpen}
      >
        <Icon as={FiHelpCircle} sx={{ boxSize: '18px', color: '#ccc' }} />
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="transparent" shadow="0">
          <ModalBody>
            <ModalCloseButton
              color="#ffffff"
              d="flex"
              position="initial"
              ml="auto"
            />
            <Box
              bg="#ffffff"
              textAlign="left"
              fontSize="14px"
              rounded="8px"
              p="24px 22px"
              fontWeight="300"
            >
              {lines.map((line, i) => (
                <Fragment key={`tooltip-${i}`}>
                  {i !== 0 && <br />}
                  <p>
                    {line.map(({ text, bold, href }, j) => {
                      let component: string | React.ReactElement = text

                      if (bold) {
                        component = (
                          <span key={`${text}-${j}`} css={{ fontWeight: 500 }}>
                            {component}
                          </span>
                        )
                      }

                      if (href) {
                        component = (
                          <a
                            key={`${text}-${j}`}
                            href={href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {component}
                          </a>
                        )
                      }

                      return component
                    })}
                  </p>
                </Fragment>
              ))}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  )
}

export const withFormControl =
  (WrappedComponent: React.FC<any>) =>
  (props: any): React.ReactElement => {
    return (
      <FormControl {...props}>
        <WrappedComponent
          {...props}
          isDisabled={!props.editable}
          editable={undefined}
        />
      </FormControl>
    )
  }

export default FormControl
