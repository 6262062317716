import React from 'react'
import { get, isEmpty } from 'lodash'
import { Box, FormLabel, Flex, Stack } from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import { HelperText } from '@/features/formGenerator/Field/FormControl'

import Text from './Text'
import type { FormControlProps } from './FormControl'

type MultilangTextProps = FormControlProps & {
  subfields: Dict
  value?: string
  isDisabled?: boolean
}

function MultilangText(props: MultilangTextProps): React.ReactElement {
  const {
    label,
    description,
    name,
    subfields,
    isRequired,
    value,
    error,
    helperText,
    ...restProps
  } = props
  const { setFieldValue } = useFormikContext()

  const transformedSubfields = Object.keys(subfields).map((key) => ({
    ...subfields[key],
    name: `${name}.${key}`,
    key,
  }))

  return (
    <Box>
      <FormLabel fontSize="18px">
        <Box>
          <Box color="#2b2b2b" mr="8px" d="inline">
            {label}
          </Box>
          {isRequired && (
            <Box color="#db6464" mr="8px" aria-hidden="true" d="inline">
              *
            </Box>
          )}
          {!isEmpty(helperText) && <HelperText lines={helperText} />}
        </Box>
        {description && (
          <Flex fontSize="14px" color="#888888">
            {description}
          </Flex>
        )}
      </FormLabel>

      <Stack>
        {transformedSubfields.map((subfield) => {
          const { name, key } = subfield

          return (
            <Text
              key={name}
              {...restProps}
              {...subfield}
              value={get(value, key, '')}
              error={get(error, key)}
              css={{
                color: '#888888',
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(name, e.target.value)
              }
            />
          )
        })}
      </Stack>
    </Box>
  )
}

export default MultilangText
