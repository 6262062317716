import { get } from 'lodash'
import jsonLogic from 'json-logic-js'

export const addDefaultRequiredMsg = (validation) => {
  const validationIndex = validation.findIndex((v) => {
    return v.includes('required')
  })

  if (validationIndex !== -1) {
    const hasCustomRequireMsg = validation[validationIndex][1]

    if (!hasCustomRequireMsg) {
      validation[validationIndex][1] = 'จำเป็นต้องตอบคำถามนี้'
    }
  }

  return [...validation]
}

export const prepareInitialFields = (fields) => {
  const transformedFields = Object.keys(fields).reduce((prev, key) => {
    const hasShowWhen = Boolean(get(fields, `${key}.show_when`))
    const validation = get(fields, `${key}.validation`)

    return {
      ...prev,
      [key]: {
        ...fields[key],
        isShow: !hasShowWhen,
        validation: addDefaultRequiredMsg(validation),
      },
    }
  }, {})

  return transformedFields
}

export const prepareInitialValues = (fields, newValues) => {
  return Object.keys(fields).reduce((prev, key) => {
    const initialValue = get(fields, `${key}.value`, '')
    let isShow = get(fields, `${key}.isShow`)

    if (newValues) {
      const rules = get(fields, `${key}.show_when`, true)

      isShow = jsonLogic.apply(rules, newValues)
    }

    if (isShow) {
      return {
        ...prev,
        [key]: initialValue,
      }
    }

    return prev
  }, {})
}

export const getIsRequired = (validationList = []) => {
  const index = validationList.findIndex((validate) => {
    return validate.includes('required')
  })

  return index !== -1
}
