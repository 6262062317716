import {
  Checkbox as ChakraCheckbox,
  CheckboxGroup,
  Stack,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import { useCreatePolicyContext } from '@/features/policies/CreatePolicy/context'
import { StepDetail } from '@/features/policies/CreatePolicy/types'
import { theme } from '@/lib/styles'

import { withFormControl } from './FormControl'
import { PremiumLabel } from './shared'

type CheckboxProps = StepDetail & {
  name: string
  isDisabled?: boolean
}

function Checkbox(props: CheckboxProps) {
  const { name, options = [], isDisabled = false } = props
  const { setFieldValue } = useFormikContext()
  const { setPremiumCount } = useCreatePolicyContext()
  const value = props.value || []

  const exclusiveValues = options
    .filter(({ exclusive }) => exclusive)
    .map(({ value }) => value)

  const handleCheckGroup = (checkedValues: string[]) => {
    const lastChecked = checkedValues[checkedValues.length - 1]

    const isExclusive = exclusiveValues.includes(lastChecked)

    if (isExclusive) {
      setFieldValue(name, [lastChecked])
    } else {
      setFieldValue(
        name,
        checkedValues.filter((val) => !exclusiveValues.includes(val)),
      )
    }
  }

  const handleCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = options.find((opt) => opt.value === event.target.value)

    if (option && option.premium) {
      if (event.target.checked) {
        setPremiumCount((value) => value + 1)
      } else {
        setPremiumCount((value) => value - 1)
      }
    }
  }

  return (
    <CheckboxGroup
      value={value}
      onChange={handleCheckGroup}
      size="lg"
      colorScheme="primary"
    >
      <Stack sx={{ mt: 3, alignItems: 'flex-start' }}>
        {options.map((option) => {
          return (
            <ChakraCheckbox
              key={option.value}
              value={option.value}
              onChange={handleCheck}
              isDisabled={isDisabled}
              fontSize="16px"
              alignItems="flex-start"
            >
              <span css={{ fontSize: '16px', fontFamily: theme.fonts.normal }}>
                {option.label}
              </span>
              {option.premium && <PremiumLabel />}
            </ChakraCheckbox>
          )
        })}
      </Stack>
    </CheckboxGroup>
  )
}

export default withFormControl(Checkbox)
