import React from 'react'

import Text from './Text'
import Checkbox from './Checkbox'
import CheckboxWith from './CheckboxWith'
import RadioGroup from './RadioGroup'
import SelectBox from './SelectBox'
import SelectCountry from './SelectCountry'
import MultilangText from './MultilangText'
import Address from './Address'

export { Text }
export { Checkbox }
export { RadioGroup }
export { SelectBox }
export { MultilangText }
export { Address }

export const fieldComponents = {
  text: Text,
  checkbox: Checkbox,
  radio: RadioGroup,
  select: SelectBox,
  select_country: SelectCountry,
  checkbox_with: CheckboxWith,
  multilang_text: MultilangText,
  address: Address,
} as const

const EmptyElement = () => {
  return <div />
}

export const getFieldComponents = (
  inputType: keyof typeof fieldComponents,
): React.FC<any> => {
  const component = fieldComponents[inputType]

  if (!component) {
    return EmptyElement
  }

  return component
}
