import { useState, useEffect } from 'react'

import { readZip } from './zip'
import { mapper, calculateObjectSimilarity } from './helper'
import { createCtx } from '../helpers'

import type {
  Address,
  AddressContextValue,
  AddressLang,
  LookedUpAddress,
} from './types'

const addressContext = createCtx<AddressContextValue>()
const [, Provider] = addressContext
export const [useAddressContext] = addressContext

export const AddressProvider = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => {
  const [address, setAddress] = useState<Address[]>([])

  useEffect(() => {
    const loadData = async () => {
      const rawAddress = await readZip('/database/db.zip')
      const rawJsonAddress = JSON.parse(rawAddress) || []
      const address = rawJsonAddress.map(mapper)

      setAddress(address)
    }

    loadData()
  }, [])

  const lookup = (
    query: string,
    lang: AddressLang = 'th',
  ): LookedUpAddress[] => {
    return address
      .map((value) => {
        const address = {
          zipCode: value.zipCode[lang],
          province: value.province[lang],
          district: value.district[lang],
          subdistrict: value.subdistrict[lang],
        }

        return {
          originalValue: value,
          zipCode: value.zipCode[lang],
          province: value.province[lang],
          district: value.district[lang],
          subdistrict: value.subdistrict[lang],
          similarity: calculateObjectSimilarity(address, query),
        }
      })
      .filter(({ similarity }) => similarity > 0.0)
      .sort(({ similarity: sim1 }, { similarity: sim2 }) => sim2 - sim1)
      .slice(0, 10)
  }

  return <Provider value={{ lookup }}>{children}</Provider>
}
