import { useState, useEffect, useMemo } from 'react'
import { get, isEmpty } from 'lodash'
import { useRouter } from 'next/router'

import { createCtx } from '@/lib/helpers'

import { Policy, StepDetail, Schema } from './types'
import { FREE_POLICY_TEMPLATE } from '../constants'

type CreatePolicyProviderProps = {
  value: {
    policy: Policy
  }
  children: React.ReactNode
}

type ContextValue = {
  currentStep: number
  totalStep: number
  premiums: number[]
  isPremium: boolean
  formSchema: Schema
  setPremiumCount: React.Dispatch<React.SetStateAction<number>>
  policy: Policy
  isEditing: boolean
}
const [useContext, ContextProvider, context] = createCtx<ContextValue>()

export const CreatePolicyProvider = ({
  value,
  children,
}: CreatePolicyProviderProps): React.ReactElement => {
  const { query } = useRouter()

  const [premiumCount, setPremiumCount] = useState(0)
  const [formSchema, setFormSchema] = useState<Schema>({
    title: get(value, 'policy.step_detail.name'),
    fields: get(value, 'policy.step_detail.fields'),
  })
  const [isEditing] = useState(query?.mode === 'edit')

  const contextValues = useMemo(() => {
    const TOTAL_STEP = get(value, 'policy.total_step')
    const currentStep = get(value, 'policy.current_step')
    const template = get(value, 'policy.template')

    const isPremium = FREE_POLICY_TEMPLATE.includes(template)
      ? premiumCount > 0
      : true

    return {
      currentStep,
      totalStep: TOTAL_STEP,
      formSchema: formSchema,
      premiums: get(value, 'policy.premiums', []),
      policy: value.policy,
      isPremium,
      setPremiumCount,
      isEditing,
    }
  }, [value, formSchema, premiumCount, setPremiumCount, isEditing])

  useEffect(() => {
    setFormSchema({
      title: get(value, 'policy.step_detail.name'),
      fields: get(value, 'policy.step_detail.fields'),
    })
  }, [
    get(value, 'policy.step_detail.name'),
    get(value, 'policy.step_detail.fields'),
  ])

  useEffect(() => {
    const premiums: number[] = get(value, 'policy.premiums', [])
    const step: number = get(value, 'policy.step')
    const stepDetail: Record<string, StepDetail> = get(
      value,
      'policy.step_detail.fields',
      {},
    )

    if (isEmpty(premiums)) {
      return setPremiumCount(0)
    }

    if (premiums.length === 1 && step === premiums[0]) {
      let premiumCount = 0
      for (const step of Object.values(stepDetail)) {
        if (step.input_type === 'checkbox') {
          for (const value of step.value) {
            const option = step.options.find((opt) => value === opt.value)
            if (option && option.premium) {
              premiumCount = premiumCount + 1
            }
          }
        }
      }

      return setPremiumCount(premiumCount)
    }

    setPremiumCount(Infinity)
  }, [value])

  return <ContextProvider value={contextValues}>{children}</ContextProvider>
}

export const CreatePolicyContext = context

export const useCreatePolicyContext = useContext
