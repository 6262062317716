import {
  Input,
  InputProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'

import { theme } from '@/lib/styles'
import { withFormControl } from './FormControl'

type TextProps = InputProps & {
  prefix?: React.ReactNode
  suffix?: React.ReactNode
}

function Text(props: TextProps) {
  const {
    name,
    placeholder,
    onChange,
    onBlur,
    onFocus,
    onClick,
    value,
    prefix,
    suffix,
    width,
    isDisabled = false,
  } = props

  return (
    <InputGroup size="lg" fontSize="16px" color="#2b2b2b" maxW={width}>
      {prefix && (
        <InputLeftElement pointerEvents="none">{prefix}</InputLeftElement>
      )}
      <Input
        id={name}
        name={name}
        aria-label={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={onClick}
        fontFamily={theme.fonts.normal}
        isDisabled={isDisabled}
        focusBorderColor="primary.400"
      />
      {suffix && (
        <InputRightElement pointerEvents="none">{suffix}</InputRightElement>
      )}
    </InputGroup>
  )
}

export default withFormControl(Text)
