export const PremiumLabel = (): React.ReactElement => {
  return (
    <span
      css={{
        borderRadius: '4px',
        backgroundImage: 'linear-gradient(287deg, #00e3ab, #32c4d7)',
        color: '#fff',
        padding: '0 8px',
        marginLeft: '15px',
        fontSize: '14px',
      }}
    >
      Premium
    </span>
  )
}
