export const POLICY_TEMPLATE = [
  'privacy',
  'cookie',
  'dpa',
  'cctv',
  'hr_privacy',
] as const

export const POLICY_STATUS = ['finished', 'draft'] as const

export const FREE_POLICY_TEMPLATE = ['privacy']
