import React from 'react'
import { Field } from 'formik'
import { get } from 'lodash'
import { Box, Grid } from '@chakra-ui/react'

import { useFormGeneratorContext } from './context'
import { getIsRequired } from './utils'
import { getFieldComponents } from '../Field'
import { useTransformedStates } from './hooks'

function Form({ renderSubmitButton, setValidationSchema }) {
  const { fields, fieldKeys, setInitialValues } = useFormGeneratorContext()

  useTransformedStates({ setValidationSchema })

  return (
    <Grid gap="24px">
      {fieldKeys.map((fieldKey) => {
        const currentField = get(fields, fieldKey)
        const { validation, isShow } = currentField

        const inputType = get(currentField, 'input_type')
        const Renderer = getFieldComponents(inputType)
        const isRequired = getIsRequired(validation)

        if (!isShow) return null

        return (
          <Field key={fieldKey} name={fieldKey}>
            {({ field, meta }) => {
              return (
                <Renderer
                  setInitialValues={setInitialValues}
                  setValidationSchema={setValidationSchema}
                  isRequired={isRequired}
                  helperText={get(currentField, 'helper_text')}
                  {...field}
                  {...currentField}
                  {...meta}
                />
              )
            }}
          </Field>
        )
      })}
      <Box>{renderSubmitButton()}</Box>
    </Grid>
  )
}

export default Form
