import { ReactNode } from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalProps as ChakraModalProps,
  CSSObject,
} from '@chakra-ui/react'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  maxW?: string
  withCloseButton?: boolean
  contentSX?: CSSObject
  closeButtonSx?: CSSObject
  closeOnOverlayClick?: boolean
} & ChakraModalProps

export const Modal = (props: ModalProps): React.ReactElement => {
  const {
    isOpen,
    onClose,
    children,
    maxW = '574px',
    withCloseButton = false,
    contentSX,
    closeButtonSx,
    closeOnOverlayClick = true,
    ...rest
  } = props

  return (
    <ChakraModal
      onClose={onClose}
      isOpen={isOpen}
      isCentered={false}
      motionPreset="slideInBottom"
      closeOnOverlayClick={closeOnOverlayClick}
      {...rest}
    >
      <ModalOverlay>
        <ModalContent maxW={maxW} pb="4" sx={contentSX}>
          {children}
          {withCloseButton && <ModalCloseButton sx={closeButtonSx} />}
        </ModalContent>
      </ModalOverlay>
    </ChakraModal>
  )
}
