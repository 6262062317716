import { get } from 'lodash'

import { RawAddress, Address, SingleLangAddress } from './types'

// export const preprocess = (address) => {
//   const { data } = address
//   const lookup = address.lookup.split('|')
//   const words = address.words.split('|')
//   const processed = []

//   const translate = (text) => {
//     const repl = (m) => {
//       const ch = m.charCodeAt(0)

//       return words[ch < 97 ? ch - 65 : 26 + ch - 97]
//     }

//     if (typeof text === 'number') {
//       text = lookup[text]
//     }
//     return text.replace(/[A-Z]/gi, repl)
//   }

//   data.forEach((provinces) =>
//     provinces[provinces.length - 1].forEach((districts) =>
//       districts[districts.length - 1].forEach((subdistricts) =>
//         [subdistricts[subdistricts.length - 1]].flat().forEach((zipCode) => {
//           processed.push({
//             province: translate(provinces[0]),
//             district: translate(districts[0]),
//             subdistrict: translate(subdistricts[0]),
//             zipCode: zipCode.toString(),
//           })
//         }),
//       ),
//     ),
//   )
//   return processed
// }

export const mapper = (address: RawAddress): Address => ({
  zipCode: {
    th: String(address.PostCode),
    en: String(address.PostCode),
  },
  province: {
    th: address.ProvinceThai,
    en: address.ProvinceEng,
  },
  district: {
    th: address.DistrictThaiShort,
    en: address.DistrictEngShort,
  },
  subdistrict: {
    th: address.TambonThaiShort,
    en: address.TambonEngShort,
  },
})

export const calculateObjectSimilarity = (
  obj1: SingleLangAddress,
  obj2: string,
): number =>
  Object.keys(obj1).reduce(
    (acc, key) =>
      acc + calculateTextSimilarity(get(obj1, key, ''), get(obj2, key, '')),
    0.0,
  )

const calculateTextSimilarity = (text1: string, text2: string): number => {
  let longer = text1
  let shorter = text2

  if (text1.length < text2.length) {
    longer = text2
    shorter = text1
  }

  const longerLength = longer.length
  if (text1.length === 0 || text2.length === 0) {
    return 0.0
  }

  return (longerLength - findIndex(longer, shorter)) / longerLength
}

const findIndex = (text1: string, text2: string): number => {
  text1 = text1.toLowerCase()
  text2 = text2.toLowerCase()

  let index = text1.indexOf(text2)

  if (index === -1) index = text1.length

  return index
}

export const findDistance = (text1: string, text2: string): number => {
  text1 = text1.toLowerCase()
  text2 = text2.toLowerCase()

  const costs: number[] = []
  text1.split('').forEach((_, i) => {
    let lastValue = i

    text2.split('').forEach((_, j) => {
      if (i === 0) {
        costs[j] = j
      } else {
        if (j > 0) {
          let newValue = costs[j - 1]
          if (text1.charAt(i - 1) !== text2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
          costs[j - 1] = lastValue
          lastValue = newValue
        }
      }
    })
    if (i > 0) costs[text2.length] = lastValue
  })

  return costs[text2.length]
}
