import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { get, isEmpty } from 'lodash'
import jsonLogic from 'json-logic-js'
import Rules from '@flipbyte/yup-schema'

import { useFormGeneratorContext } from './context'

export const useTransformedStates = (params) => {
  const { setValidationSchema } = params
  const {
    fields,
    fieldKeys,
    setFields,
    setInitialValues,
  } = useFormGeneratorContext()
  const { values } = useFormikContext()

  const transformFieldState = (prev, key, isShow) => {
    return {
      ...prev,
      [key]: {
        ...fields[key],
        isShow,
      },
    }
  }

  const transformValuesState = (prev, key, isShow) => {
    const currentValue = values[key] || ''

    if (!isShow) return prev

    return {
      ...prev,
      [key]: currentValue,
    }
  }

  const transformValidationSchema = (prev, key, isShow) => {
    const emptyValidation = isEmpty(get(fields, `${key}.validation`))

    if (!isShow || emptyValidation) return prev

    return {
      ...prev,
      [key]: get(fields, `${key}.validation`),
    }
  }

  const transformedStates = fieldKeys.reduce((prev, key) => {
    const rules = get(fields[key], 'show_when', true)
    const isShow = jsonLogic.apply(rules, values)

    return [
      transformFieldState(prev[0], key, isShow),
      transformValuesState(prev[1], key, isShow),
      transformValidationSchema(prev[2], key, isShow),
    ]
  }, [])

  useEffect(() => {
    const [newFields, newValues, newValidationSchema] = transformedStates

    setFields(newFields)
    setInitialValues(newValues)
    setValidationSchema(new Rules([['object', newValidationSchema]]).toYup())
  }, [values])
}
