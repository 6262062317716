import { Theme } from 'react-select'

import { theme } from '@/lib/styles'

export const themeConfig = (selectThemes: Theme): Theme => {
  return {
    ...selectThemes,
    borderRadius: 5,
    colors: {
      ...selectThemes.colors,
      primary: theme.colors.primary[400],
      primary75: theme.colors.primary[200],
      primary50: theme.colors.primary[100],
      primary25: theme.colors.primary[50],
      neutral40: theme.colors.gray[400],
      neutral30: theme.colors.gray[300],
      neutral20: theme.colors.gray[200],
      neutral10: theme.colors.gray[100],
      neutral5: theme.colors.gray[50],
    },
  }
}

export const customStyles = {
  control: (controlStyles: Dict): Dict => {
    return {
      ...controlStyles,
      minHeight: '48px',
    }
  },
}
