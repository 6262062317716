import { AxiosError } from 'axios'
import { useToast, UseToastOptions } from '@chakra-ui/toast'

import { ERROR_TOAST_CONFIG } from '../constants'

import getErrorMsg from '../helpers/getErrorMsg'

type UseErrorHandlerReturn = (error: AxiosError) => void

export const useErrorHandler = (): UseErrorHandlerReturn => {
  const toast = useToast()

  return function (
    error: AxiosError<{ errors: string[] }>,
    toastConfig?: UseToastOptions,
  ) {
    const errors = error.response?.data.errors

    if (errors) {
      errors.forEach((error) => {
        toast({
          ...ERROR_TOAST_CONFIG,
          description: error.toCapitalize(),
          ...toastConfig,
        })
      })
    } else {
      toast({
        ...ERROR_TOAST_CONFIG,
        description: getErrorMsg(error),
        ...toastConfig,
      })
    }
  }
}
