import React from 'react'
import { Grid, FormLabel, Box, Checkbox, Input } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { find, get, noop } from 'lodash'
import produce from 'immer'

import { cleanChildren } from './utils'
import { withFormControl } from '../FormControl'
import { PremiumLabel } from '../shared'

import SelectCountry from './SelectCountry'
import { SelectOption } from '../../types'

type CheckBoxValue = {
  checked: string
}

type CheckboxWithProps = {
  name: string
  options: SelectOption[]
  value: CheckBoxValue[]
  isDisabled?: boolean
}

function CheckboxWith(props: CheckboxWithProps) {
  const { name, options = [], value, isDisabled = false } = props
  const { setFieldValue } = useFormikContext()

  return (
    <CheckboxGroup
      name={name}
      value={value}
      onChange={(checkboxValues: CheckBoxValue[]) =>
        setFieldValue(name, checkboxValues)
      }
    >
      {options.map((option) => {
        return (
          <CustomCheckBox
            key={option.value}
            option={option}
            value={option.value}
            isDisabled={isDisabled}
          />
        )
      })}
    </CheckboxGroup>
  )
}

type CheckboxGroupProps = {
  value?: CheckBoxValue[]
  name: string
  children?: React.ReactNode
  onChange?: (value: CheckBoxValue[]) => void
  isDisabled?: boolean
}

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const {
    value: fieldValue = [],
    children,
    onChange = noop,
    isDisabled = false,
  } = props
  const validChildren = cleanChildren(children)

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target
    let newValues

    if (checked) {
      newValues = [...fieldValue, { checked: value, sub_field_value: '' }]
    } else {
      newValues = fieldValue.filter(({ checked }) => checked !== value)
    }

    onChange(newValues)
  }

  const clone = validChildren.map((child, index) => {
    return React.cloneElement(child as React.ReactElement, {
      name: `${props.name}-${index}`,
      fieldName: props.name,
      onChange: _onChange,
      isChecked: Boolean(
        find(fieldValue, {
          checked: (child as React.ReactElement)?.props?.value,
        }),
      ),
      isDisabled,
    })
  })

  return <Grid gap="10px">{clone}</Grid>
}

type CustomCheckBoxProps = {
  value: string
  option: SelectOption
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  isChecked?: boolean
  fieldName?: string
  isDisabled?: boolean
}

const CustomCheckBox = React.forwardRef(
  (props: CustomCheckBoxProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      option,
      isChecked,
      fieldName = '',
      isDisabled,
      value,
      onChange,
      name,
    } = props
    const { values, setFieldValue } = useFormikContext()
    const checkboxKey = option.value

    const fieldValue = get(values, fieldName)
    const checkboxValues = find(fieldValue, { checked: checkboxKey })
    const checkValue = get(checkboxValues, 'check')
    const moreFieldValue = get(checkboxValues, 'sub_field_value')
    const subField = get(option, 'sub_field')

    const onChangeMoreInput = (val: string) => {
      const targetIndex = (values as Dict)[fieldName].findIndex(
        ({ checked }: CheckBoxValue) => {
          return checked === option.value
        },
      )

      const newValue = produce((values as Dict)[fieldName], (draft: Dict) => {
        draft[targetIndex].sub_field_value = val
      })

      setFieldValue(fieldName, newValue)
    }

    return (
      <React.Fragment>
        <Checkbox
          size="lg"
          colorScheme="primary"
          value={value === undefined ? checkValue : value}
          fontSize="16px"
          ref={ref}
          isDisabled={isDisabled}
          isChecked={isChecked}
          name={name}
          onChange={onChange}
        >
          <span css={{ fontSize: '16px' }}>{option.label}</span>
          {option.premium && <PremiumLabel />}
        </Checkbox>

        {isChecked && subField && (
          <MoreInput
            value={moreFieldValue}
            onChange={onChangeMoreInput}
            {...subField}
          />
        )}
      </React.Fragment>
    )
  },
)

type MoreInputProps = {
  name: string
  label: string
  value: string
  onChange?: (val: string) => void
}

const MoreInput = (props: MoreInputProps) => {
  const { name, label, value, onChange = noop } = props
  const inputType = get(props, 'input_type')

  if (inputType === 'select_country') {
    return (
      <SelectCountry
        name={name}
        data-testid={name}
        value={value}
        onChange={(v) => onChange(v)}
      />
    )
  }

  return (
    <Box>
      <FormLabel mt="5px">{label}</FormLabel>
      <Input
        name={name}
        data-testid={name}
        autoFocus
        fontSize="16px"
        focusBorderColor="primary.400"
        size="lg"
        value={value}
        required
        onChange={(e) => onChange(e.target.value)}
      />
    </Box>
  )
}

export default withFormControl(CheckboxWith)
