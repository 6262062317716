import React, { useState } from 'react'
import { isEmpty, get } from 'lodash'
import { Box, Flex } from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import Text from '../Text'

import { useAddressContext } from '@/lib/address'

const AddressSelect = (props) => {
  const { name, onChange, field, lang = 'th' } = props

  const [suggestion, setSuggestion] = useState([])
  const [isShow, setIsShow] = useState(false)

  const { lookup } = useAddressContext()
  const { setFieldValue, values } = useFormikContext()

  const parentName = name.split('.')[0]

  const handleChange = (e) => {
    onChange(e)

    const parentValue = get(values, parentName)

    const suggestion = lookup(
      {
        zipCode: parentValue.zip_code,
        district: parentValue.district,
        subdistrict: parentValue.subdistrict,
        province: parentValue.province,
        [field]: e.target.value,
      },
      lang,
    )

    setSuggestion(suggestion)
  }

  const handleSelectOption = (option) => {
    [
      { field: 'zip_code', key: 'zipCode' },
      { field: 'district', key: 'district' },
      { field: 'subdistrict', key: 'subdistrict' },
      { field: 'province', key: 'province' },
    ].forEach(({ field, key }) => {
      ['th', 'en'].forEach((lang) => {
        setFieldValue(
          `${parentName}.${lang}.${field}`,
          get(option, `originalValue.${key}.${lang}`),
        )
      })
    })
    setIsShow(false)
  }

  return (
    <Box pos="relative">
      <Text
        {...props}
        onChange={(e) => handleChange(e)}
        onFocus={() => setIsShow(true)}
        onBlur={() => setTimeout(() => setIsShow(false), 200)}
        {...(isShow && { error: undefined })}
      />
      {isShow && !isEmpty(suggestion) && (
        <Box
          pos="absolute"
          width="100%"
          zIndex="1"
          top="calc(100% - 2px)"
          border="1px solid #e2e8f0"
          color="#888888"
          fontWeight="300"
          borderTop="0"
          rounded="8px"
          roundedTop="0"
        >
          <Box width="100%" h="2px" />
          <Box h="200px" overflow="scroll">
            {suggestion.map((option) => {
              return (
                <Flex
                  key={`${name}-${option.zipCode}-${option.subdistrict}-${option.district}-${option.province}`}
                  onClick={() => handleSelectOption(option)}
                  sx={{
                    alignItems: 'center',
                    bg: 'white',
                    borderBottom:  "1px solid #e2e8f0",
                    cursor: 'pointer', minH: '36px',
                    p: '2px 4px',
                    wordBreak: 'break-word',
                    _hover: {
                      backgroundColor: '#e8e8e8',
                    }
                  }}
                >
                  {`${option.zipCode} >> ${option.subdistrict} >> ${option.district} >> ${option.province}`}
                </Flex>
              )
            })}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default AddressSelect
