import JSZip from 'jszip'
import axios from 'axios'

export const readZip = async (file) => {
  const { data } = await axios.get(file, { responseType: 'arraybuffer' })

  return await JSZip.loadAsync(data).then((zip) =>
    zip.file('db.json').async('string'),
  )
}
