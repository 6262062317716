import React from 'react'
import { useFormikContext } from 'formik'
import Select from 'react-select'

import { withFormControl } from '../FormControl'
import { customStyles, themeConfig } from './config'

import { SelectOption } from '../../types'

export type SelectBoxProps = {
  name: string
  placeholder: string
  options: SelectOption[]
  value: string
  isDisabled?: boolean
}

function SelectBox(props: SelectBoxProps) {
  const {
    name,
    options = [],
    value = [],
    placeholder = 'Select option',
    isDisabled = false,
  } = props

  const { setFieldValue } = useFormikContext()

  return (
    <Select
      id={name}
      instanceId={name}
      name={name}
      placeholder={placeholder}
      options={options}
      styles={customStyles}
      theme={themeConfig}
      onChange={(option) => {
        setFieldValue(name, option?.value)
      }}
      isDisabled={isDisabled}
      {...(value && {
        defaultValue: {
          label: value,
          value,
        },
      })}
    />
  )
}

export default withFormControl(SelectBox)
