import React from 'react'
import { useFormikContext } from 'formik'
import Select from 'react-select'

import { withFormControl } from '../FormControl'
import countries from './countries.json'
import { customStyles, themeConfig } from '../SelectBox/config'

export type SelectCountryProps = {
  name: string
  placeholder: string
  value: string
  isDisabled?: boolean
}

function SelectCountry(props: SelectCountryProps) {
  const {
    name,
    value,
    placeholder = 'Select country',
    isDisabled = false,
  } = props

  const { setFieldValue } = useFormikContext()

  const options = countries.map(({ name }) => {
    return {
      label: name,
      value: name,
    }
  })

  return (
    <Select
      id={name}
      instanceId={name}
      name={name}
      placeholder={placeholder}
      options={options}
      styles={customStyles}
      theme={themeConfig}
      onChange={(option) => {
        setFieldValue(name, option?.value)
      }}
      isDisabled={isDisabled}
      {...(value && {
        defaultValue: { label: value, value },
        value: { label: value, value },
      })}
    />
  )
}

export default withFormControl(SelectCountry)
