import Text from '../Text'
import AddressSelect from './AddressSelect'

export const languagesConfig = [
  {
    language: 'th',
    label: 'ภาษาไทย',
    inputs: [
      { key: 'address', placeholder: 'เลขที่ หมู่ ซอย ถนน', type: 'text' },
      { key: 'zip_code', placeholder: 'รหัสไปรษณีย์', type: 'address' },
      { key: 'district', placeholder: 'เขต/อำเภอ', type: 'address' },
      { key: 'subdistrict', placeholder: 'แขวง/ตำบล', type: 'address' },
      { key: 'province', placeholder: 'จังหวัด', type: 'address' },
    ],
  },
  {
    language: 'en',
    label: 'ภาษาอังกฤษ',
    inputs: [
      {
        key: 'address',
        placeholder: 'No., alley, road (e.g., 1 Sukhumvit Rd.)',
        type: 'text',
      },
      { key: 'zip_code', placeholder: 'Postal Code', type: 'address' },
      { key: 'district', placeholder: 'District', type: 'address' },
      { key: 'subdistrict', placeholder: 'Subdistrict', type: 'address' },
      { key: 'province', placeholder: 'Province', type: 'address' },
    ],
  },
]

const fieldComponents = {
  text: Text,
  address: AddressSelect,
}

export const getFieldComponents = (inputType) => {
  const component = fieldComponents[inputType]

  if (!component) {
    return null
  }

  return component
}
