import { UseToastOptions } from '@chakra-ui/react'

export const TOAST_CONFIG: UseToastOptions = {
  isClosable: true,
  position: 'top-right',
  status: 'success',
  title: 'สำเร็จ',
}

export const ERROR_TOAST_CONFIG: UseToastOptions = {
  ...TOAST_CONFIG,
  status: 'error',
  title: 'เกิดข้อผิดพลาด',
  variant: 'left-accent',
}
