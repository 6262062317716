import React from 'react'
import Select from 'react-select'
import { noop } from 'lodash'
import countries from '../SelectCountry/countries.json'
import { customStyles, themeConfig } from '../SelectBox/config'

export type SelectCountryProps = {
  name: string
  placeholder?: string
  value: string
  isDisabled?: boolean
  onChange?: (value: string) => void
}

function SelectCountry(props: SelectCountryProps): React.ReactElement {
  const {
    name,
    value,
    placeholder = 'Select country',
    onChange = noop,
    isDisabled,
  } = props

  const options = countries.map(({ name }) => {
    return {
      label: name,
      value: name,
    }
  })

  return (
    <Select
      id={name}
      instanceId={name}
      name={name}
      placeholder={placeholder}
      options={options}
      styles={customStyles}
      theme={themeConfig}
      onChange={(option) => {
        onChange(option?.value)
      }}
      isDisabled={isDisabled}
      {...(value && {
        defaultValue: { label: value, value },
      })}
    />
  )
}

export default SelectCountry
