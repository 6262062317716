/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  useState,
  useContext,
  useEffect,
  createContext,
  forwardRef,
} from 'react'
import { get } from 'lodash'
import { prepareInitialFields, prepareInitialValues } from './utils'

import { Schema, StepDetail } from '@/features/policies/CreatePolicy/types'

export const FormGeneratorContext = createContext({})

export const useFormGeneratorContext = (): any => {
  return useContext(FormGeneratorContext)
}

type Props = {
  value: {
    schema: Schema
  }
  initialValues: any
  children: React.ReactNode
}

export const FormGeneratorProvider = (props: Props): React.ReactElement => {
  const { value, initialValues: initialValuesProp } = props
  const schema = get(value, 'schema')

  const [fields, setFields] = useState<Record<string, StepDetail>>(() =>
    prepareInitialFields(get(schema, 'fields')),
  )

  const [initialValues, setInitialValues] = useState<
    Record<string, StepDetail>
  >(() => initialValuesProp || prepareInitialValues(fields))

  const fieldKeys = Object.keys(fields)

  const contextValues = {
    fields,
    fieldKeys,
    setFields,
    initialValues,
    setInitialValues,
  }

  useEffect(() => {
    const newFields = prepareInitialFields(get(schema, 'fields'))
    const newInitialValues = prepareInitialValues(
      newFields,
      prepareInitialValues(newFields),
    )

    setFields(newFields)
    setInitialValues(newInitialValues)
  }, [schema])

  useEffect(() => {
    if (initialValuesProp) {
      setInitialValues(initialValuesProp)
    }
  }, [initialValuesProp])

  return (
    <FormGeneratorContext.Provider value={contextValues}>
      {props.children}
    </FormGeneratorContext.Provider>
  )
}

export const withFormGeneratorProvider = (
  WrappedComponent: React.FC<any>,
): React.ForwardRefExoticComponent<any> =>
  forwardRef(
    (props: Dict, ref: React.ForwardedRef<any>): React.ReactElement => {
      const { schema, initialValues } = props

      return (
        <FormGeneratorProvider initialValues={initialValues} value={{ schema }}>
          <WrappedComponent {...props} ref={ref} />
        </FormGeneratorProvider>
      )
    },
  )
