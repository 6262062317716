import React from 'react'
import { Radio, RadioGroup as ChakraRadioGroup, Stack } from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import { theme } from '@/lib/styles'
import { withFormControl } from './FormControl'
import { PremiumLabel } from './shared'

import { SelectOption } from '../types'

type RadioGroupProps = {
  name: string
  options: SelectOption[]
  value?: string | number
  isDisabled?: boolean
}

function RadioGroup(props: RadioGroupProps) {
  const { name, options = [], value = '', isDisabled = false } = props
  const { setFieldValue } = useFormikContext()

  return (
    <ChakraRadioGroup
      value={value}
      onChange={(text) => setFieldValue(name, text)}
    >
      <Stack sx={{ mt: 3, alignItems: 'flex-start' }}>
        {options.map((option) => {
          return (
            <Radio
              key={option.value}
              value={option.value}
              size="lg"
              colorScheme="primary"
              alignItems="flex-start"
              isDisabled={isDisabled}
            >
              <span
                css={{
                  fontSize: '16px',
                  fontWeight: 300,
                  fontFamily: theme.fonts.normal,
                }}
              >
                {option.label}
              </span>
              {option.premium && <PremiumLabel />}
            </Radio>
          )
        })}
      </Stack>
    </ChakraRadioGroup>
  )
}

export default withFormControl(RadioGroup)
